import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import CourseList from './CourseList';
import CourseDetail from './CourseDetail';

const Home = () => <div>
  <nav>
        <ul>
          <li>
            <Link to="/courses">Danh sách bài tập</Link>
          </li>
        </ul>
      </nav>
</div>;
const About = () => <div>About Tab</div>;
const Contact = () => <div>Contact Tab</div>;

const Tabs = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  return (
    <div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<CourseList />} />
        <Route path="/course/:id" element={<CourseDetail />} />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Tabs />
    </Router>
  );
};

export default App;
