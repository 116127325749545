import axios from "axios";
import SpeechRecognition from "./routes/SpeechRecognition";
import React, { useEffect, useState } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import { Link } from "react-router-dom";

function CourseList() {
  const [value, setValue] = useState("");
  const [contentActive, setContentActive] = useState("1");
  const [keyboicanh, setKeyboicanh] = useState("1");
  const [boicanhs, setBoicanhs] = useState([]);
  const [courseId, setCourseId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        "https://ewise.edu.vn/wp-json/wp/v2/conversation_course/"
      );
      console.log("====================================");
      console.log(
        response,
        "https://ewise.edu.vn/wp-json/wp/v2/conversation_course"
      );
      console.log("====================================");

      setBoicanhs(response.data);
    };

    fetchData();
  }, []);

  const renderListContent = boicanhs.map((i) => {
    return (
      <div className="col-4 p-2" key={i}>
        <Link to={`/course/` + i.id}>
          <div
            className={`card alert ${
              i.key == contentActive ? "alert-success" : "alert-primary"
            }`}
          >
            {i.title?.rendered}
          </div>
        </Link>
      </div>
    );
  });

  return (
    <div className="container">
      <h4>Chọn bối cảnh</h4>
      <div className="row">{renderListContent}</div>
    </div>
  );
}

export default CourseList;
